
<template>
  <TransitionRoot as="template" :show="isPurchaseModalOpen">
      <Dialog as="div" auto-reopen="true" class="fixed z-10 inset-0 overflow-y-auto" @close="this.closePurchaseModal()">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 dark:bg-true-gray-900 dark:bg-opacity-75 bg-warm-gray-900 bg-opacity-75" />
          </TransitionChild>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom dark:bg-true-gray-800 bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <!-- Close Button -->
              <div v-if="this.slide_index != 4" class="block absolute top-0 right-0 pt-4 pr-4">
                <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="this.closePurchaseModal()">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Slides -->
              <div class="relative px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto py-4 mt-4">
                <!-- Preload images here -->
                <img class="hidden" :src="currentPrice.qr_img" rel="preload" />
                <img class="hidden" :src="currentPrice.sc_img" rel="preload" />
                
                <!-- STEP 0 -->
                <div v-if="this.slide_index == 0" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">Major Update</span>
                  <!-- <img src="https://i.pinimg.com/originals/99/6e/47/996e47166a6ffe11ff6d4b23ecdd7cea.jpg" class="rounded-xl w-84 my-4" alt=""> -->
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="block xl:inline">Take Control of Your Study Journey!</span>
                  </h1>
                  <p>
                    Great news for our future aviation mechanics! We understand your journey is unique, so we're introducing an exciting update: <strong class="text-yellow-500 dark:text-yellow-300">Buy Now, Activate Later</strong>.
                  </p>
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="text-4xl">
                      🔧
                    </div>
                    <p>
                      <strong class="text-black dark:text-white">Flexibility at Your Fingertips:</strong> Buy the reviewer during a sale or at your convenience, but start your 6-month reviewer access whenever you're ready for your license exam.
                    </p>
                  </div>
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="text-4xl">
                      🌟
                    </div>
                    <p>
                      <strong class="text-black dark:text-white">Study Smart, Pass Faster:</strong> Our research shows that many students can ace their exam in <em>just 2 months</em> with our App! While you have a maximum of 6 months, you might be ready sooner than you think.
                    </p>
                  </div>
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="text-4xl">
                      ⏰
                    </div>
                    <p>
                      <strong class="text-black dark:text-white">Commit to Success:</strong> When you activate your reviewer, you're making a commitment to your future. This helps you stay focused! Let's get that license in under 6 months, we're here to help!
                    </p>
                  </div>

                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="text-4xl">
                      🛫
                    </div>
                    <p>
                      <strong class="text-black dark:text-white">Ready to Embark on Your Journey?</strong> Click the button below to proceed to the payment instructions. Once your payment is confirmed, you have the freedom to activate the reviewer at any time that suits you. 
                    </p>
                  </div>

                  <div class="my-6">
                    <h1 class="text-xl tracking-tight font-bold dark:text-gray-100 text-gray-700 md:text-2xl mb-4">
                      <span class="block xl:inline">Select type of subscription:</span>
                    </h1>
                    <div class="grid gap-2 md:grid-cols-2">
                      <button v-for="price, i in prices" @click="selectPrice(price)" class="border rounded-md p-2" :class="price.amount == currentPrice.amount ? 'dark:text-yellow-300 text-yellow-600 dark:border-yellow-300 border-yellow-600 bg-yellow-500 bg-opacity-10':'border-true-gray-300 dark:border-true-gray-500 opacity-60'">
                        <p> PHP {{ price.amount.toLocaleString() }}</p>
                        <p> {{ price.durationInWords }} upon Activation</p>
                      </button>
                    </div>
                  </div>

                  <button @click="this.nextStep(1)" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Continue
                  </button>
                </div>
                <!-- STEP 1 -->
                <div v-if="this.slide_index == 1" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">{{ modalTitle }}</span>
                  <!-- <img src="https://i.pinimg.com/originals/99/6e/47/996e47166a6ffe11ff6d4b23ecdd7cea.jpg" class="rounded-xl w-84 my-4" alt=""> -->
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="block xl:inline">Instructions for Payment via GCash</span>
                  </h1>
                  <p>
                    You will be guided through the steps for purchasing the CAAP reviewer. Here's a summary before we proceed:
                  </p>
                  <div class="flex items-start my-4 space-x-4 ">
                    <div class="mx-auto font-bold flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
                      </svg>
                    </div>
                    <p>
                      Simply scan the provided QR Code with GCash to make your payment.
                    </p>
                  </div>
                  
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="mx-auto flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                      </svg>
                    </div>
                    <p>
                      After completing the payment, take a <strong>screenshot</strong> of the transaction confirmation in the GCash app. Note down your transaction's <strong>Reference Number</strong> (Ref. No.), and then click the <strong>"Payment Sent!"</strong> button to finalize the process.
                    </p>
                  </div>
                  <div class="flex items-start my-4 space-x-4 my-8">
                    <div class="mx-auto flex-shrink-0 flex items-center dark:text-gray-800 justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                      </svg>
                    </div>
                    <p>
                      Your access to the CAAP reviewer will be enabled <strong>within 24 hours</strong> after payment is received and confirmed. Keep an eye on your email for a confirmation message.
                    </p>
                  </div>
                  <button @click="this.nextStep(2)" class="mt-2 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Continue
                  </button>    
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(0)">Go back</a>
                  </div>
                </div>

                <!-- STEP 2 -->
                <div v-if="this.slide_index == 2" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">{{ modalTitle }}</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 1:</span>{{ ' ' }}
                    <span class="">Scan the QR Code</span>
                  </h1>
                  <div :class="[(!isLoaded) ? 'animate-pulse' : '', 'rounded-xl bg-white h-80 my-8']">
                    <img class="mx-auto h-80" @load="isLoaded = true" :src="currentPrice.qr_img" rel="preload" />
                  </div>
                  <p>
                    Open your GCash App and scan the QR code above. This should take you to the GCash Express Send page where the payment details are already populated. If there are any issues with the QR Code, you can send the <b>PHP {{ currentPrice.amount.toLocaleString() }}</b> payment to GCash number <strong class="text-yellow-500 dark:text-yellow-400">{{ gcashPhoneNum }}</strong>, or contact your Endorser for further assistance. Click the button below once done. 
                  </p>
                  <button @click="this.nextStep(3)" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Proceed To Next Step
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(1)">Go back</a>
                  </div>
                </div>

                <!-- STEP 3 -->
                <!-- <div v-if="this.slide_index == 2" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">{{ modalTitle }}</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 2:</span>{{ ' ' }}
                    <span>Add Your Email and Send Payment</span>
                  </h1>
                  <img class="-mt-4 mb-8 sm:w-4/6 mx-auto" @load="isLoaded = true" src="https://firebasestorage.googleapis.com/v0/b/skilltech-prod.appspot.com/o/images%2Fmockups%2Fskilltech-gcash-screenshot-2999-step2.png?alt=media&token=6455890a-dbbf-4f4e-a9b5-b7f9468e98f5" />
                  <p>
                    Please add your account <strong class="text-yellow-500 dark:text-yellow-400">email address</strong> in the GCash payment <strong>message</strong> so we can confirm it's you (see screenshot above for reference). Click the button below once you're done.
                  </p>

                  <button @click="this.nextStep(3)" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Proceed To Next Step
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(1)" >Go back</a>
                  </div>
                  
                </div> -->

                <!-- STEP 4 -->
                <div v-if="this.slide_index == 3" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">{{ modalTitle }}</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span class="dark:text-yellow-200 text-yellow-400">Step 2:</span>{{ ' ' }}
                    <span>Enter your Reference Number below</span>
                  </h1>
                  <img class="-mt-4 mb-8 sm:w-4/6 mx-auto" @load="isLoaded = true" :src="currentPrice.sc_img" />
                  
                  
                  <p>
                    Don't forget to take a <strong class="text-yellow-500 dark:text-yellow-400">screenshot</strong> of the successful transaction in your GCash app for your copy and save it. Input the <strong class="text-yellow-500 dark:text-yellow-400">Ref. No. of your receipt</strong> below (see sample screenshot above). Once done, click the <strong>"Payment Sent!"</strong> button below to complete the payment. 
                  </p>
                  <div class="mt-4">
                    <input v-model="refno" type="text" name="refno" id="refno" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-200 border-2 rounded-lg p-4" placeholder="Enter your 13-digit Reference Number (i.e. Ref. No.) here"/>
                  </div>
                  <button :disabled="!isRefNoValid" @click="this.prepareToOnboard()" class="disabled:bg-gray-400 mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-xl font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-30 disabled:pointer-events-none disabled:cursor-auto">
                    Payment Sent! 
                  </button>
                  <div class="mt-4 text-center w-full text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300 mx-auto text-sm font-medium">
                    <a href="#" @click="this.nextStep(2)" >Go back</a>
                  </div>           
                </div>

                <!-- STEP 5 -->
                <div v-if="this.slide_index == 4" class="dark:text-gray-400 text-gray-600 leading-6 text-md max-w-prose mx-auto">
                  <span class="block text-base text-center dark:text-yellow-300 text-yellow-500 font-semibold tracking-wide uppercase">{{ modalTitle }}</span>
                  <h1 class="text-2xl tracking-tight font-extrabold dark:text-gray-300 text-gray-700 md:text-3xl my-6">
                    <span v-if="!readyToExit" class="block xl:inline">One moment please...</span>
                    <span v-else class="block xl:inline">Awesome! <span class="dark:text-yellow-200 text-yellow-400">Thank You</span> for your purchase.</span>
                  </h1>
                  <p>
                    We are excited for you to start your review! Your CAAP access will be granted after payment is received and confirmed. Expect an email confirmation within 24 hours! If you have any concerns, please reach out to us via email at <strong>support@skilltech.ph</strong> or through our official <strong>Facebook Messenger</strong>.
                  </p>

                  <div v-if="!readyToExit" class="relative pt-1 mt-8">
                    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                        <div
                        :style="`width: ${progress}%`"
                        class="transition-all duration-500 ease-in-out shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500"
                        ></div>
                    </div>
                  </div>
                  
                  <div class="bg-yellow-50 overflow-hidden rounded-lg text-warm-gray-500 text-left mt-8 max-w-full">
                    <div class="px-4 py-5 sm:py-8 sm:px-9 flex space-x-2 items-start">
                      <div>
                        <LightBulbIcon class="text-yellow-400 w-5 h-5" />
                      </div>
                      <p class="text-yellow-700">
                        <strong>Tip:</strong> {{tips[current]}}
                      </p>
                    </div>
                  </div>
                  
                  <button @click="this.closePurchaseModal()" :disabled="!readyToExit" class="mt-8 text-white bg-indigo-600 w-full py-4 border border-transparent rounded-md shadow-sm px-4 inline-flex justify-center text-lg font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-auto">
                    Close
                  </button>
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
</template>
 
<script>
// Search for 'switch-gcash-code' and the files that need to be updated will be shown.

// John QR codes
// import QR_CODE_IMG_3999 from '@/assets/images/qr/QR-3999.png';
// import QR_CODE_IMG_5999 from '@/assets/images/qr/QR-5999.png';
// const GCASH_PHONE_NUM = "09062846028";

// Veen QR codes
import QR_CODE_IMG_3999 from '@/assets/images/qr/bogs/QR-3999.png';
import QR_CODE_IMG_5999 from '@/assets/images/qr/bogs/QR-5999.png';
const GCASH_PHONE_NUM = "09058562354";

import SC_IMG_3999 from '@/assets/images/sc/SC-3999.png';
import SC_IMG_5999 from '@/assets/images/sc/SC-5999.png';


import moment from "moment";
import firebase from "@/firebase/config";
// import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
const db = firebase.firestore();

import { LightBulbIcon, XIcon } from "@heroicons/vue/solid";
import { homeData } from "@/data/home.data"

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";

const functions = firebase.functions();
const callZapierWebhook = functions.httpsCallable('callZapierWebhook');

export default {
  props: ["isPurchaseModalOpen", "user"],
  components: {
    LightBulbIcon, XIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  data(){
    return {
      modalTitle: 'Purchase CAAP Knowledge Reviewer',
      // ui data
      isLoading: false,
      slide_index: 0,
      progress: 0, // progress bar
      readyToExit: false,
      tips: homeData.tips,
      current: 0,
      isLoaded: false,
      refno: "",
      currentPrice: null,
      // ORIG_PRICE_QR: ORIG_PRICE_QR,
      // ORIG_PRICE_SC: ORIG_PRICE_SC,
      gcashPhoneNum: GCASH_PHONE_NUM,
      prices: [
        {
          amount: 3999, 
          duration: 90, 
          durationInWords: '3 Months',
          qr_img: QR_CODE_IMG_3999,
          sc_img: SC_IMG_3999,
        },
        {
          amount: 5999, 
          duration: 180, 
          durationInWords: '6 Months',
          qr_img: QR_CODE_IMG_5999,
          sc_img: SC_IMG_5999,
        },
      ],
    };
  },
  computed: {
    isRefNoValid(){
      if(this.refno.length < 13 ) return false  // invalid if less than 13 chars
      if(this.refno.length > 13 ) return false  // invalid if more than 13 chars
      if(isNaN(this.refno)) return false        // invalid if not a number
      return true
    },
  },
  mounted() {
    this.currentPrice = this.prices[0];
  },
  methods: {
    selectPrice(price){
      this.currentPrice = price;
    },
    closePurchaseModal() {
      if(this.slide_index == 4){
        if(!this.readyToExit){
          return
        }else{
          /**
           * We need to reload the window after the user successfully
           * purchased a reviewer. This way, the updated userInfo will be 
           * fetched. They can then access CAAP 101 immediately after they
           * purchased.
           */
          this.$emit("closePurchaseModal");
          // location.reload();
        }
      }
      
      this.$emit("closePurchaseModal");
      setTimeout(() => {
        this.slide_index = 0;  
      }, 500);
      
    },
    nextStep(index){
      this.slide_index = index;
      document.querySelector("body").scrollTop;
    },
    
    async prepareToOnboard(){
      // let isValidEndorserCode = await userService.isValidEndorserCode(db, this.user.endorser)
      // if(!isValidEndorserCode){
      //   alert("Endorser code not found")
      // }else{
        // this.user.level = (this.isStudent) ? 'Undergraduate' : '';
        // console.log("user: ", this.user)
        
        this.nextStep(4) // navigate to the final slide
        this.onboardUser(); // run loaders and tips
      // }    
    },

    onboardUser(){
            
      var current_progress = 0;
      var step = 0.05; // the smaller this is the slower the progress bar

      console.log("Processing payment...")

      // run interval for progress bar
      const interval = setInterval( () => {
          current_progress += step;
          this.progress = Math.round(Math.atan(current_progress) / (Math.PI / 2) * 100 * 1000) / 1000
          if (this.progress >= 100){
              clearInterval(interval);
          }else if(this.progress >= 2) {
              step = 1.0
          }
      }, 100);

      // run interval for tips
      const tipsInterval = setInterval(() => {
        this.current = (this.current < this.tips.length-1) ? this.current+1 : 0;
        console.log(this.current)
      }, 4000);

      let _reviewers = {...this.user.reviewers};

      _reviewers.caap = 'paid';

      let _caap_data = {
        purchase_date: moment().valueOf().toString(),
        gcash_refno: this.refno
      }

      // Update as of Nov 26, 2023: All reviewer purchases are now 'Activate Later' by default. Users can just activate the reviewer anytime.
      _caap_data.finalPrice = this.currentPrice.amount;
      _caap_data.duration = this.currentPrice.duration;
      _caap_data.durationInWords = this.currentPrice.durationInWords;
      _caap_data.activateLater = true;
      
      // Update User Data
      db.collection("users").doc(this.user.id).update({
        reviewers: _reviewers,
        caap_data: _caap_data
      })
      .then(() => { 
        console.log("Successfully updated user") 
        
        clearInterval(tipsInterval); 
        clearInterval(interval);
        
        // enable buttons and everything
        this.progress = 100; // set progress to 100
        
        setTimeout(() => {
          // add 1 second before removing the loader
          this.readyToExit = true;
          this.user.reviewers.caap = 'paid';
          this.user.caap_data = _caap_data;
        }, 1000)

      })
      .catch((error) => { 
        console.error("Error updating user data: ", error)  
      });

    },
  },
};
</script>